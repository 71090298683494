<template>
  <div class="row h-100">
    <div class="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
      <div class="d-table-cell align-middle">
        <div class="text-center mt-4">
          <h1 class="h2">Get started</h1>
          <p class="lead">Sign up for free today and starting mocking APIs!</p>
        </div>

        <div class="card">
          <div class="card-body">
            <div class="m-sm-4">
              <p class="card-text">
                <span class="text-muted"
                  >Already have an account?
                  <b-link :to="{ name: 'login' }">Login</b-link></span
                >
              </p>
              <form id="frmCreate" @submit.prevent="createUser">
                <b-form-group
                  label="First Name"
                  label-for="input-first-name"
                  :invalid-feedback="invalid.firstNameFeedback"
                  :state="invalid.name"
                >
                  <b-form-input
                    id="input-first-name"
                    v-model="user.firstName"
                    :state="invalid.firstName"
                    trim
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Last Name"
                  label-for="input-last-name"
                  :invalid-feedback="invalid.lastNameFeedback"
                  :state="invalid.lastName"
                >
                  <b-form-input
                    id="input-last-name"
                    v-model="user.lastName"
                    :state="invalid.lastName"
                    trim
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Email"
                  label-for="input-email"
                  :invalid-feedback="invalid.emailFeedback"
                  :state="invalid.email"
                >
                  <b-form-input
                    id="input-email"
                    type="email"
                    v-model="user.email"
                    :state="invalid.email"
                    trim
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Password"
                  label-for="input-password"
                  :invalid-feedback="strength.feedback"
                  :state="invalid.password"
                >
                  <b-form-input
                    id="input-password"
                    class="password-input"
                    type="password"
                    v-model="user.password"
                    :state="invalid.password"
                    trim
                    @input="measurePasswordStrength"
                  ></b-form-input>
                  <div class="progress password-strength-bar">
                    <div
                      id="password-strength-bar"
                      :class="
                        'progress-bar progress-bar-danger ' + strength.color
                      "
                      role="progressbar"
                      aria-valuenow="0"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      :style="'width: ' + strength.width + '%'"
                    >
                      <span class="sr-only">0</span>
                    </div>
                  </div>
                </b-form-group>
                <b-form-group
                  label="Confirm Password"
                  label-for="input-confirm"
                  :invalid-feedback="invalid.confirmFeedback"
                  :state="invalid.confirm"
                >
                  <b-form-input
                    id="input-confirm"
                    type="password"
                    v-model="user.confirm"
                    :state="invalid.confirm"
                    trim
                  ></b-form-input>
                </b-form-group>
                <div class="form-group">
                  <b-form-checkbox id="tos" name="tos" v-model="user.terms">
                    I agree to the
                    <b-link :to="{ name: 'terms' }"
                      >terms and conditions</b-link
                    >
                    of using this service
                    <b-form-invalid-feedback :state="invalid.terms"
                      >You must accept the terms and
                      conditions.</b-form-invalid-feedback
                    >
                  </b-form-checkbox>
                  <b-form-checkbox
                    name="news_enroll"
                    v-model="user.news_enroll"
                  >
                    I want to receive news and updates from API Faker
                  </b-form-checkbox>
                </div>
                <b-button type="submit" variant="primary"> Sign Up </b-button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import firebase from "firebase";
import FirebaseMixin from "../mixins/Firebase";
import UtilsMixin from "../mixins/Utils";
import Users from "../firebase/users";
const zxcvbnImport = () => import("zxcvbn");

export default {
  name: "SignUp",
  inject: ["notyf"],
  mixins: [FirebaseMixin, UtilsMixin],
  data() {
    return {
      ref: "users",
      user: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirm: "",
        terms: null,
        news_enroll: null,
      },
      invalid: {
        firstName: null,
        lastName: null,
        email: null,
        password: null,
        confirm: null,
        terms: null,
      },
      strength: {
        width: 0,
        color: "",
        feedback: "",
      },
    };
  },
  watch: {
    userUid: {
      immediate: true,
      handler(uid) {
        if (uid) {
          console.log(uid);
        }
      },
    },
  },
  computed: {
    ...mapState(["isAuthenticated", "userProfile"]),
    userUid() {
      return this.userProfile.uid;
    },
  },
  methods: {
    ...mapMutations(["setUser", "setSearchKey"]),
    measurePasswordStrength() {
      zxcvbnImport().then((module) => {
        const zxcvbn = module.default;
        const result = zxcvbn(this.user.password);
        console.log(result);
        switch (result.score) {
          case 1:
            this.strength.width = 25;
            this.strength.color = "bg-danger";
            this.invalid.password = false;
            break;
          case 2:
            this.strength.width = 50;
            this.strength.color = "bg-warning";
            this.invalid.password = true;
            break;
          case 3:
            this.strength.width = 75;
            this.strength.color = "bg-success";
            this.invalid.password = true;
            break;
          case 4:
            this.strength.width = 100;
            this.strength.color = "bg-success";
            this.invalid.password = true;
            break;
          default:
            if (result.score === 0 && this.user.password.length > 0) {
              this.strength.width = 10;
            } else {
              this.strength.width = 0;
            }
            this.strength.color = "bg-danger";
            this.invalid.password = false;
        }
        this.strength.feedback = result.feedback.warning;
      });
    },
    validateForm() {
      // Reset form validations
      for (let field in this.invalid) {
        if (field !== "password") {
          this.invalid[field] = null;
          this.invalid[field + "Feedback"] = "";
        }
      }
      // Validate fields
      let isValid = true;
      if (!this.user.firstName || this.user.firstName.length === 0) {
        this.invalid.firstName = false;
        this.invalid.firstNameFeedback = "Please enter a valid first name.";
        isValid = false;
      } else {
        this.invalid.firstName = true;
      }
      if (!this.user.lastName || this.user.lastName.length === 0) {
        this.invalid.lastName = false;
        this.invalid.lastNameFeedback = "Please enter a valid last name.";
        isValid = false;
      } else {
        this.invalid.lastName = true;
      }
      if (!this.user.email || !this.validateEmail(this.user.email)) {
        this.invalid.email = false;
        this.invalid.emailFeedback = "Please enter a valid email.";
        isValid = false;
      } else {
        this.invalid.email = true;
      }
      if (!this.user.password || this.user.password.length === 0) {
        this.invalid.password = false;
        this.strength.feedback = "Please enter a valid password.";
        isValid = false;
      }
      if (!this.user.confirm || this.user.password !== this.user.confirm) {
        this.invalid.confirm = false;
        this.invalid.confirmFeedback = "Passwords do not match.";
        isValid = false;
      } else {
        this.invalid.confirm = true;
      }
      if (!this.user.terms) {
        this.invalid.terms = false;
        isValid = false;
      } else {
        this.invalid.terms = true;
      }
      return isValid;
    },
    createUser() {
      if (!this.validateForm()) {
        return false;
      }
      firebase
        .auth()
        .createUserWithEmailAndPassword(this.user.email, this.user.password)
        .then(() => {
          const u = new Users();
          const user = {
            firstName: this.user.firstName,
            lastName: this.user.lastName,
            email: this.user.email,
            newsEnroll: this.user.news_enroll,
          };
          u.provision(user)
            .then((resp) => {
              this.setUser({ ...resp.data });
              this.navigate("dashboard");
              u.onLogin().then((resp) => {
                this.setSearchKey(resp.data.searchKey);
              });
            })
            .catch((error) => {
              console.log(error);
              this.notyf.error(
                "An error occurred while creating your account. Please try again later."
              );
            });
        })
        .catch((error) => {
          console.log(error);
          this.notyf.error(
            "An error occurred while creating your account. Please try again later."
          );
        });
    },
  },
  created() {},
};
</script>

<style scoped>
.password-input {
  border-radius: 0.25rem 0.25rem 0 0;
}

.password-strength-bar {
  height: 5px;
  margin-bottom: 0;
  overflow: hidden;
  background-color: #ced4da;
  border-radius: 0 0 0.25rem 0.25rem;
}

#password-feedback {
  height: 24px;
}
</style>
