<template>
  <div class="row h-100">
    <div class="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
      <div class="d-table-cell align-middle">
        <div class="text-center mt-4">
          <h1 class="h2">Welcome!</h1>
          <p class="lead">
            Please complete the form to finish setting up your account.
          </p>
        </div>

        <div class="card">
          <div class="card-body">
            <div class="m-sm-4">
              <form id="frmCreate" @submit.prevent="updateUser">
                <b-form-group
                  label="First Name"
                  label-for="input-first-name"
                  :invalid-feedback="invalid.firstNameFeedback"
                  :state="invalid.name"
                >
                  <b-form-input
                    id="input-first-name"
                    v-model="user.firstName"
                    :state="invalid.firstName"
                    trim
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Last Name"
                  label-for="input-last-name"
                  :invalid-feedback="invalid.lastNameFeedback"
                  :state="invalid.lastName"
                >
                  <b-form-input
                    id="input-last-name"
                    v-model="user.lastName"
                    :state="invalid.lastName"
                    trim
                  ></b-form-input>
                </b-form-group>
                <div class="form-group">
                  <b-form-checkbox id="tos" name="tos" v-model="user.terms">
                    I agree to the
                    <b-link :to="{ name: 'terms' }"
                      >terms and conditions</b-link
                    >
                    of using this service
                    <b-form-invalid-feedback :state="invalid.terms"
                      >You must accept the terms and
                      conditions.</b-form-invalid-feedback
                    >
                  </b-form-checkbox>
                  <b-form-checkbox
                    name="news_enroll"
                    v-model="user.news_enroll"
                  >
                    I want to receive news and updates from API Faker
                  </b-form-checkbox>
                </div>
                <b-button type="submit" variant="primary"> Finish </b-button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import FirebaseMixin from "../mixins/Firebase";
import UtilsMixin from "../mixins/Utils";
import Users from "../firebase/users";

export default {
  name: "ProviderSignUp",
  inject: ["notyf"],
  mixins: [FirebaseMixin, UtilsMixin],
  data() {
    return {
      ref: "users",
      user: {
        firstName: "",
        lastName: "",
        terms: null,
        news_enroll: null,
      },
      invalid: {
        firstName: null,
        lastName: null,
        terms: null,
      },
    };
  },
  watch: {
    userUid: {
      immediate: true,
      handler(uid) {
        if (uid) {
          console.log(uid);
        }
      },
    },
  },
  computed: {
    ...mapState(["isAuthenticated", "userProfile"]),
    userUid() {
      return this.userProfile.uid;
    },
  },
  methods: {
    ...mapMutations(["setUser", "setSearchKey"]),
    validateForm() {
      // Reset form validations
      for (let field in this.invalid) {
        if (field !== "password") {
          this.invalid[field] = null;
          this.invalid[field + "Feedback"] = "";
        }
      }
      // Validate fields
      let isValid = true;
      if (!this.user.firstName || this.user.firstName.length === 0) {
        this.invalid.firstName = false;
        this.invalid.firstNameFeedback = "Please enter a valid first name.";
        isValid = false;
      } else {
        this.invalid.firstName = true;
      }
      if (!this.user.lastName || this.user.lastName.length === 0) {
        this.invalid.lastName = false;
        this.invalid.lastNameFeedback = "Please enter a valid last name.";
        isValid = false;
      } else {
        this.invalid.lastName = true;
      }
      if (!this.user.terms) {
        this.invalid.terms = false;
        isValid = false;
      } else {
        this.invalid.terms = true;
      }
      return isValid;
    },
    updateUser() {
      if (!this.validateForm()) {
        return false;
      }
      const u = new Users();
      const user = {
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        email: this.userProfile.email,
        newsEnroll: this.user.news_enroll,
      };
      u.provision(user)
        .then((resp) => {
          this.setUser({ ...resp.data });
          this.navigate("dashboard");
          u.onLogin().then((resp) => {
            this.setSearchKey(resp.data.searchKey);
          });
        })
        .catch((error) => {
          console.log(error);
          this.notyf.error(
            "An error occurred while creating your account. Please try again later."
          );
        });
    },
  },
  created() {},
};
</script>

<style scoped>
.password-input {
  border-radius: 0.25rem 0.25rem 0 0;
}

.password-strength-bar {
  height: 5px;
  margin-bottom: 0;
  overflow: hidden;
  background-color: #ced4da;
  border-radius: 0 0 0.25rem 0.25rem;
}

#password-feedback {
  height: 24px;
}
</style>
